
import { App } from '@capacitor/app';

// Above the createApp() line
import { defineCustomElements } from 'stripe-pwa-elements/loader';

export default defineNuxtPlugin((nuxtApp) => {

    onNuxtReady(async () => {

        defineCustomElements();

    })

})