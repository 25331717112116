export default defineI18nLocale(async (locale) => {
    return {
        "Your temporary store name is currently {name}.": "Tu nombre de tienda temporal es actualmente {name}.",
        "Host": "Gerente",
        "Owner": "Catálogo",
        "Staff": "Ventas",
        "HOST": "Gerente",
        "OWNER": "Catálogo",
        "STAFF": "Ventas",
        "Your store is currently {status}.": "Tu tienda está actualmente {status}.",
        "Your email {email} has been successfully verified. You can now go back to the App.": "Tu correo electrónico {email} ha sido verificado exitosamente. Ahora puedes regresar a la app.",
        // onboard
        "We just need some basic info to get your store setup. You’ll be able to edit this later.": "Solo necesitamos información básica para configurar tu tienda. Podrás editar esto más tarde.",
        // Team
        "Team Members": "Miembros del equipo",
        "Invite a member": "Invitar a un miembro",
        "Add others to your account": "Agregar otros a tu cuenta",
        "Add people who help manage your store. They will be able to see everything you can see.": "Agregar personas que ayudan a administrar tu tienda. Podrán ver todo lo que tú puedes ver.",
        "Send invite": "Enviar invitación",
        // team
        "Invited at": "Invitado en",
        "Resend invite": "Reenviar invitación",
        "Cancel invite": "Cancelar invitación",
        "Remove user": "Eliminar usuario",
        // currency
        "Preferred currency": "Moneda preferida",
        "Currency": "Moneda",
        "Select a currency": "Seleccionar una moneda",
        // language
        "Preferred language": "Idioma preferido",
        "Select a language": "Seleccionar un idioma",
        "Language": "Idioma",
        // passkey
        "Use biometrics": "Usar biometría",
        "Log in with your Face ID": "Iniciar sesión con tu Face ID",
        "Log in with your fingerprint": "Iniciar sesión con tu huella digital",
        // auth/passkey
        "Next time, log in with face or fingerprint": "La próxima vez, inicia sesión con tu rostro o huella digital",
        "Using a passkey to log in is as easy as unlocking your device.": "Usar una clave de acceso para iniciar sesión es tan fácil como desbloquear tu dispositivo.",
        "Yes, use fingerprint": "Sí, usar huella digital",
        "Skip for now": "Omitir por ahora",
        "Biometric authentication is now set up": "La autenticación biométrica está ahora configurada",
        "Turn in off anytime form settings.": "Desactivar la configuración de formulario en cualquier momento.",
        // invite
        "Invite": "Invitar",
        "The link to your invitation has expired or is not valid. Please contact your referrer for a reinvite.": "El enlace de tu invitación ha expirado o no es válido. Por favor, contacta a tu referente para una nueva invitación.",
        "You have been invited to join {team_name} by {invited_by}": "Has sido invitado a unirte a {team_name} por {invited_by}",
        "Thank you for registering {name}": "Gracias por registrarte, {name}",
        "We\'re glad that you\'re with us": "Estamos contentos de que estés con nosotros",
        "Accept invitation": "Aceptar invitación",
        "Your account email address is": "Tu dirección de correo electrónico de la cuenta es",
        // passwords
        "Change password": "Cambiar contraseña",
        "Last updated": "Última actualización",
        "Change your account password": "Cambia la contraseña de tu cuenta",
        "Current password": "Contraseña actual",
        "New password": "Nueva contraseña",
        "Confirm password": "Confirmar contraseña",
        "Update password": "Actualizar contraseña",
        // change-password
        "Set a new password": "Establecer una nueva contraseña",
        "Reset password": "Restablecer contraseña",
        "Your password was reset successfully": "Tu contraseña ha sido restablecida exitosamente",
        "Now you can go and login with your new password.": "Ahora puedes ir e iniciar sesión con tu nueva contraseña.",
        "Password reset links expire after an hour for security purposes, please request another password reset link below to continue": "Los enlaces de restablecimiento de contraseña expiran después de una hora por motivos de seguridad, por favor solicita otro enlace de restablecimiento de contraseña a continuación para continuar",
        // forgot-password
        "Reset your password": "Restablecer tu contraseña",
        "Enter your e-mail and we will send you a link to change your password": "Ingresa tu correo electrónico y te enviaremos un enlace para cambiar tu contraseña",
        "Send password reset link": "Enviar enlace de restablecimiento de contraseña",
        "Reset email sent": "Correo de restablecimiento enviado",
        "Please check your inbox and make sure it\'s you or not and reset your password": "Por favor, verifica tu bandeja de entrada y asegúrate de que eres tú o no y restablece tu contraseña",
        "Resent email": "Correo reenviado",
        "Did you receive this email? Check your inbox or": "¿Recibiste este correo? Revisa tu bandeja de entrada o",
        "Already have an account?": "¿Ya tienes una cuenta?",
        // email
        "Forgot password?": "¿Olvidaste tu contraseña?",
        "Email address": "Dirección de correo electrónico",
        "Confirm email": "Confirmar correo",
        "Confirm your email address": "Confirma tu dirección de correo electrónico",
        "We sent a confirmation email to": "Enviamos un correo de confirmación a",
        "Check your email and click on the confirmation link to continue.": "Revisa tu correo y haz clic en el enlace de confirmación para continuar.",
        "Resend email": "Reenviar correo",
        // confirm-email
        "Email is verified!": "¡El correo está verificado!",
        "This link has expired": "Este enlace ha expirado",
        "Sorry! We couldn\'t verify your email, please request another link below to continue.": "¡Lo siento! No pudimos verificar tu correo, por favor solicita otro enlace a continuación para continuar.",
        // phone
        "Phone number": "Número de teléfono",
        "Add phone number": "Agregar número de teléfono",
        "Confirm phone": "Confirmar teléfono",
        "Enter verification code": "Ingresa el código de verificación",
        "Add your phone number": "Agrega tu número de teléfono",
        "Please enter the six-digit code we just sent to your number": "Por favor, ingresa el código de seis dígitos que acabamos de enviar a tu número",
        "Please enter the five-digit code we just sent to your number": "Por favor, ingresa el código de cinco dígitos que acabamos de enviar a tu número",
        "Resend code": "Reenviar código",
        "Code": "Código",
        "Use a different phone number": "Usar un número de teléfono diferente",
        "Wrong phone number?": "¿Número de teléfono incorrecto?",
        // subscription-item
        "per month": "por mes",
        "Cancel subscription": "Cancelar suscripción",
        // subscriptions
        "Billing and Subscriptions": "Facturación y Suscripciones",
        "Get more information about managing your apps subscriptions, billing history, and more.": "Obtén más información sobre cómo gestionar las suscripciones de tus aplicaciones, el historial de facturación y más.",
        // address
        "Address": "Dirección",
        "Country": "País",
        "Street address": "Dirección de la calle",
        "Street number": "Número de la calle",
        "Apt, suite. (Optional)": "Apt, suite. (Opcional)",
        "City": "Ciudad",
        "State": "Estado",
        "Postalcode": "Código postal",
        // name
        "These details could be publicly available.": "Estos detalles podrían estar disponibles públicamente.",
        // privacy
        "Delete account": "Eliminar cuenta",
        "Permanently delete your account and all of your data.": "Eliminar permanentemente tu cuenta y todos tus datos.",
        "Are you sure you want to delete your account?": "¿Estás seguro de que deseas eliminar tu cuenta?",
        "When you delete your account, all of your data will be deleted and can't be retrieved. Deleting your account cannot be undone.": "Cuando eliminas tu cuenta, todos tus datos serán eliminados y no se pueden recuperar. Eliminar tu cuenta no se puede deshacer.",
        "No, keep my account": "No, mantener mi cuenta",
        "Yes, delete my account": "Sí, eliminar mi cuenta",
        // store/name
        "Name your store": "Nombra tu tienda",
        "Your store name is currently {name}.": "El nombre de tu tienda es actualmente {name}.",
        "Name store": "Nombre de la tienda",
        "Store Name": "Nombre de la tienda",
        // store/status
        "Your store is currently": "Tu tienda está actualmente",
        // domain
        "Change to a new domain": "Cambiar a un nuevo dominio",
        "Your domain name is currently": "Tu nombre de dominio es actualmente",
        "Change domain": "Cambiar dominio",
        "Store details": "Detalles de la tienda",
        "Domain Name": "Nombre de dominio",
        "Our suggested use is": "Nuestro uso sugerido es",
        // auth/login
        "Sign in to your account": "Iniciar sesión en tu cuenta",
        "Welcome back you\'ve been missed": "Bienvenido de nuevo, te hemos extrañado",
        "Continue with Email": "Continuar con correo electrónico",
        "Continue with Phone Number": "Continuar con número de teléfono",
        "Continue with Bio": "Continuar con biometría",
        "Continue with Google": "Continuar con Google",
        "Continue with Facebook": "Continuar con Facebook",
        "Don\'t have an account yet?": "¿Aún no tienes una cuenta?",
        // auth/signup
        "Hello, register now!": "¡Hola, regístrate ahora!",
        "Let\'s create your account here": "Vamos a crear tu cuenta aquí",
        // apps
        "We play nice with your favorite software.": "Nos llevamos bien con tu software favorito.",
        "Back to App Directory": "Volver al directorio de aplicaciones",
        "Get Started": "Empezar",
        // apps/subscribe
        "Select a plan": "Seleccionar un plan",
        "Plan": "Plan",
        "billed yearly at": "facturado anualmente en",
        "Subscribe": "Suscribirse",
        "Login Successful": "Inicio de sesión exitoso",
        "You have successfully signed into your account. You can close this window and continue using the app.": "Has iniciado sesión en tu cuenta exitosamente. Puedes cerrar esta ventana y continuar usando la app.",
        // Properties
        "Properties": "Propiedades",
        "Property": "Propiedad",
        "properties": "propiedades",
        "property": "propiedad",
        "DRAFT": "Borrador",
        "PENDING": "Pending",
        "PUBLISHED": "Published",
        "ARCHIVED": "Archived",
        "APARTMENT": "Apartamento",
        "HOUSE": "Casa",
        "LOFT": "Desván",
        "BOAT": "Barco",
        "CAMPER_RV": "Autocaravana",
        "CONDOMINIUM": "Condominio",
        "CHALET": "Chalet",
        "BED_BREAKFAST": "Bedreakfast breakfast",
        "VILLA": "Village",
        "TENT": "Tienda",
        "CABIN": "Cabaña",
        "TOWNHOUSE": "Casa adosada",
        "BUNGALOW": "Bungalow",
        "HUT": "Choza",
        "Sort by": "Sort by",
        "Property Information": "Información de la propiedad",
        "Property status": "Estado de la propiedad",
        "Property type": "Tipo de propiedad",
        "Property name": "Nombre de la propiedad",
        "Property address": "Dirección de la propiedad",
        "Property photos": "Fotografías de la propiedad",
        "Country of location": "País de ubicación",
        "Photo captions": "Leyendas de las fotografías",
        "Manual property registration": "Registro manual de propiedad",
        "Choose a name for your property": "Elija un nombre para su propiedad",
        "Property created successfully": "Propiedad creada exitosamente",
        "An error occurred while listing properties": "Se produjo un error al enumerar propiedades.",
        "An error occurred while loading the property": "Se produjo un error al cargar la propiedad.",
        "An error occurred while creating the property": "Se produjo un error al crear la propiedad.",
        // Generic
        "Dashboard": "Tablero",
        "Booking": "Reservas",
        "Calendar": "Calendario",
        "Messages": "Mensajes",
        "Account": "Cuenta",
        "Preferences": "Preferencias",
        "Personal info": "Información personal",
        "Information": "Información",
        "Details": "detalles",
        "Support": "Soporte",
        "About": "Acerca de",
        "Pricing": "Precios",
        "Profile": "Perfil",
        "Billing": "Facturación",
        "Settings": "Configuraciones",
        "Platform": "Plataforma",
        "App Marketplace": "Mercado de aplicaciones",
        "Sign up": "Registrarse",
        "Sign in": "Iniciar sesión",
        "Today": "Hoy",
        "Privacy": "Privacidad",
        "Security": "Seguridad",
        "Store": "Tienda",
        "Free": "Gratis",
        "Menu": "Menú",
        "Home": "Home",
        "Bookings": "Reservas",
        "Status": "Estado",
        "Logout": "Cerrar sesión",
        "Listings": "Listados",
        "Need help?": "¿Necesita ayuda?",
        "Visit our Help Center.": "Visite nuestro Centro de ayuda.",
        "Terms of Service": "Condiciones de servicio",
        "Privacy Policy": "Política de privacidad",
        "Cookie Policy": "Política de cookies",
        "Version": "Versión",
        "You": "Tú",
        "Save": "Guardar",
        "Edit": "Editar",
        "Cancel": "Cancelar",
        "Change": "Cambiar",
        "Continue": "Continuar",
        "Configure": "Configurar",
        "Verify": "Verificar",
        "Apply": "Aplicar",
        "Login": "Iniciar sesión",
        "Enable": "Habilitar",
        "Disable": "Deshabilitar",
        "Accept": "Aceptar",
        "Decline": "Rechazar",
        "Search": "Buscar",
        "Filter": "Filtro",
        "Filters": "Filtros",
        "Opps": "Ups",
        "Name": "Nombre",
        "Full Name": "Nombre completo",
        "Password": "Contraseña",
        "E-mail": "Correo electrónico",
        "Phone": "Teléfono",
        "Time zone": "Zona horaria",
        "Clear filters": "Limpiar filtros",
        "Refine your search": "Refinar su búsqueda",
        "Back": "Atrás",
        "Next": "Próximo",
        "Welcome": "Bienvenido",
        "Welcome to Ermith!": "¡Bienvenido a Ermith!",
        "Not defined": "No definido",
        "Go to the App": "Ir a la app",
        "OTHER": "Otro",
        "Select your country": "Selecciona tu país",
        "or": "o",
        "Use my location": "Usar mi ubicación",
        "I don't know my zip code": "No sé mi código postal",
        "CEP": "CEP",
        "Neighborhood": "Vecindario",
        "Complement": "Complementar",
        "Number": "Número",
        "Postal Code": "Código Postal",
        // Forms
        "Minimum 5 characters": "Mínimo 5 caracteres",
        "Required": "Obligatorio",
        "Required field": "Campo obligatorio",
        "Name is a required field": "El nombre es un campo obligatorio",
        "Country is a required field": "El país es un campo obligatorio",
        "CEP is a required field": "CEP es un campo obligatorio",
        "State is a required field": "El estado es un campo obligatorio",
        "City is a required field": "La ciudad es un campo obligatorio",
        "Neighborhood is a required field": "El barrio es un campo obligatorio",
        "Address is a required field": "La dirección es un campo obligatorio",
        "Complement is a required field": "El complemento es un campo obligatorio",
        "Number is a required field": "El número es un campo obligatorio",
        "60 character limit": "Límite de 60 caracteres",
    }
})
