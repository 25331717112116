import { Capacitor } from "@capacitor/core"
import { Device } from '@capacitor/device';
import { initializeApp } from "firebase/app"
import { FirebaseMessaging, type GetTokenOptions } from "@capacitor-firebase/messaging"

export const useNotificationStore = defineStore('notification', () => {

    const config = useRuntimeConfig()

    const isServiceWorkerSupported = useSupported(() => navigator && 'serviceWorker' in navigator)

    const isNotificationSupported = useSupported(() => {
        return Capacitor.getPlatform() === "web" && isServiceWorkerSupported.value || Capacitor.isNativePlatform();
    })

    const preference = ref()

    const promptState = ref()

    const notifications = ref([])

    const initializeAppFirebase = async () => {

        if (!Capacitor.isNativePlatform()) {
            initializeApp(config.public.firebase);
        }

        FirebaseMessaging.addListener("notificationReceived", (event) => {
            console.log("notificationReceived: ", { event });
        })

        FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
            console.log("notificationActionPerformed: ", { event });
        })

        FirebaseMessaging.addListener("tokenReceived", (event) => {
            console.log("tokenReceived: ", { event });

            console.log(event.token)

        })

        if (Capacitor.getPlatform() === "web" && isServiceWorkerSupported.value) {

            navigator.serviceWorker.addEventListener("message", (event: any) => {

                console.log("serviceWorker message: ", { event });

                const notification = new Notification(event.data.notification.title, {
                    body: event.data.notification.body,
                })
                notification.onclick = (event) => {
                    console.log("notification clicked: ", { event })
                }

            })
        }

        FirebaseMessaging.checkPermissions().then((value) => {
            promptState.value = value.receive
        })

    }

    const checkPermissions = async () => {
        const result = await FirebaseMessaging.checkPermissions();
        return result.receive;
    };

    const requestPermissions = () => {

        // if (Capacitor.isNativePlatform()) {
        //     return;
        // }

        const fetchResult = createEventHook<any>()
        const fetchError = createEventHook<any>()

        FirebaseMessaging.isSupported().then(() => {

            FirebaseMessaging.requestPermissions().then((data) => {

                if (data.receive === 'granted') {
                    getFirebaseToken()
                }
    
                promptState.value = data.receive
    
                fetchResult.trigger(data)
    
            }).catch((error) => {
    
                promptState.value = error.receive
    
                fetchError.trigger(error.data)
            })

        }).catch((error) => {
    
            fetchError.trigger(error.data)
        })

        return {
            onResult: fetchResult.on,
            onError: fetchError.on
        }

    }

    const getFirebaseToken = async () => {

        const options: GetTokenOptions = {
            vapidKey: config.public.firebase.vapidKey,
        };

        if (Capacitor.getPlatform() === "web" && isServiceWorkerSupported.value) {
            options.serviceWorkerRegistration = await navigator.serviceWorker.register("firebase-messaging-sw.js");
        }

        const { token } = await FirebaseMessaging.getToken(options)

        updatePreferences(preference.value, {
            registration_token: {
                ...await Device.getId(),
                platform: Capacitor.getPlatform().toUpperCase(),
                value: token
            }
        })

        console.log(token)

    }

    const getPreferences = () => {

        const fetchResult = createEventHook<any>()
        const fetchError = createEventHook<any>()

        $fetch(`${config.public.apiBase}/api/v1/notifications/preferences`, {
            headers: {
                'Authorization': `Bearer ${useCookie('token').value}`
            }
        }).then((data) => {

            preference.value = data

            fetchResult.trigger(data)

        }).catch((error) => {

            fetchError.trigger(error.data)
        })

        return {
            onResult: fetchResult.on,
            onError: fetchError.on
        }

    }

    const updatePreferences = (_preference: any, values: any) => {

        const fetchResult = createEventHook<any>()
        const fetchError = createEventHook<any>()

        const body = Object.assign({}, _preference, values)

        $fetch(_preference._links.self.href, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${useCookie('token').value}`
            },
            body: body,
        }).then((data) => {

            preference.value = data

            fetchResult.trigger(data)

        }).catch((error) => {

            fetchError.trigger(error.data)
        })

        return {
            onResult: fetchResult.on,
            onError: fetchError.on
        }

    }

    const updateSubscription = (subscription: any, values: any) => {

        const fetchResult = createEventHook<any>()
        const fetchError = createEventHook<any>()

        $fetch(subscription._links.subscribe.href, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${useCookie('token').value}`
            },
            body: {
                send_email: values.sendEmail,
                send_push: values.sendPush,
                send_sms: values.sendSms
            },
        }).then((data) => {

            getPreferences()

            fetchResult.trigger(data)

        }).catch((error) => {

            fetchError.trigger(error.data)
        })

        return {
            onResult: fetchResult.on,
            onError: fetchError.on
        }

    }

    const getNotifications = () => {

        const fetchResult = createEventHook<any>()
        const fetchError = createEventHook<any>()

        $fetch(`${config.public.apiBase}/api/v1/notifications`, {
            headers: {
                'Authorization': `Bearer ${useCookie('token').value}`
            }
        }).then((data) => {

            fetchResult.trigger(data)

            notifications.value = data._embedded.notifications

        }).catch((error) => {

            fetchError.trigger(error.data)
        })

        return {
            onResult: fetchResult.on,
            onError: fetchError.on
        }

    }

    const deleteNotification = (notification: any) => {

        const fetchResult = createEventHook<any>()
        const fetchError = createEventHook<any>()

        $fetch(notification._links.self.href, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${useCookie('token').value}`
            }
        }).then((data) => {

            getNotifications()

            fetchResult.trigger(data)

        }).catch((error) => {

            fetchError.trigger(error.data)
        })

        return {
            onResult: fetchResult.on,
            onError: fetchError.on
        }

    }
    
    return {
        preference, getPreferences, updateSubscription, initializeAppFirebase, checkPermissions,
        requestPermissions, getFirebaseToken, promptState, updatePreferences,
        notifications, getNotifications, deleteNotification, isNotificationSupported
    }

})