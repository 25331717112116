import { Capacitor } from '@capacitor/core'
import * as Sentry from '@sentry/nuxt';
import { Preferences } from '@capacitor/preferences'

export const useAuthStore = defineStore('auth', () => {

    const config = useRuntimeConfig()

    const toast = useToast()

    const user = ref()

    const authenticated = ref(false)
    const forgotPasswordEmailSent = ref(false)
    const otpToken = ref(null)

    const isLoggedOut = ref(false)

    const login = (username: any, password: any) => {

        const fetchResult = createEventHook<any>()
        const fetchError = createEventHook<any>()

        $fetch(`${config.public.apiBase}/api/v1/authentication/process`, {
            method: 'POST',
            body: {
                username,
                password
            },
            //ignoreResponseError: true
        }).then(data => {

            console.log(data)

            fetchResult.trigger(data)

            persistToken(data.tokens[0].value)

        }).catch((error) => {

            const data = error.data

            console.log(error.data)

            fetchError.trigger(error.data)

            toast.add({ severity: 'error', summary: 'Sign in', detail: data.error.exception, life: 3000 })

        })

        return {
            onResult: fetchResult.on,
            onError: fetchError.on
        }
    }

    const logout = async () => {

        const token = useCookie('token')
        authenticated.value = false
        token.value = null

        // Set isLoggedOut to true to prevent passkey unlock
        isLoggedOut.value = true

        navigateTo('/auth/login')
    }

    const register = (values: any) => {

        const fetchResult = createEventHook<any>()
        const fetchError = createEventHook<any>()

        $fetch(`${config.public.apiBase}/api/v1/authentication/onboard`, {
            method: 'POST',
            body: values
        }).then(data => {

            console.log(data)

            persistToken(data.tokens[0].value)

            fetchResult.trigger(data)

        }).catch((error) => {

            const data = error.data

            console.log(error.data)

            fetchError.trigger(error.data)

            toast.add({ severity: 'error', summary: 'Sign in', detail: data.error.exception, life: 3000 })

        })

        return {
            onResult: fetchResult.on,
            onError: fetchError.on
        }
    }

    const forgotPassword = (values: any) => {

        const fetchResult = createEventHook<any>()
        const fetchError = createEventHook<any>()

        $fetch(`${config.public.apiBase}/api/v1/authentication/forgot_password`, {
            method: 'POST',
            body: values
        }).then(data => {

            fetchResult.trigger(data)
        }).catch((error) => {

            const data = error.data

            console.log(error.data)

            fetchError.trigger(error.data)

            toast.add({ severity: 'error', summary: 'Sign in', detail: data.error.exception, life: 3000 })
        })

        return {
            onResult: fetchResult.on,
            onError: fetchError.on
        }

    }

    const changePassword = (token: String, values: any) => {

        const fetchResult = createEventHook<any>()
        const fetchError = createEventHook<any>()

        $fetch(`${config.public.apiBase}/api/v1/authentication/change_password`, {
            method: 'PUT',
            body: values,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((data) => {

            fetchResult.trigger(data)
        }).catch((error) => {

            toast.add({ severity: 'error', summary: 'Account', detail: error.data.error.exception, life: 3000 })
            fetchError.trigger(error.data)
        })

        return {
            onResult: fetchResult.on,
            onError: fetchError.on
        }
    }

    const verifyEmail = (token: any) => {

        const fetchResult = createEventHook<any>()
        const fetchError = createEventHook<any>()

        $fetch(`${config.public.apiBase}/api/v1/auth/users/verify_email`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(data => {

            fetchResult.trigger(data)
        }).catch((error) => {

            const data = error.data

            console.log(error.data)

            fetchError.trigger(error.data)

            toast.add({ severity: 'error', summary: 'Account', detail: data.error.exception, life: 3000 })
        })

        return {
            onResult: fetchResult.on,
            onError: fetchError.on
        }
    }

    const getCurrentUser = async () => {

        const { data } = await useFetch(`${config.public.apiBase}/api/v1/auth/me`, {
            headers: {
                'Authorization': `Bearer ${useCookie('token').value}`
            }
        })

        // Sentry scopes
        if (data?.value) {
            Sentry.setTag("user_type", data.value.user_type)
            Sentry.setUser({
                id: data.value.id,
                email: data.value.email
            })
        }

        user.value = data.value
    }

    const itsMe = (userId: any) => {
        return user.value.id == userId;
    }

    const requestOtp = (values: any) => {

        const fetchResult = createEventHook<any>()
        const fetchError = createEventHook<any>()

        $fetch(`${config.public.apiBase}/api/v1/authentication/request_otp`, {
            method: 'POST',
            body: values,
        }).then(data => {

            console.log(data)

            fetchResult.trigger(data)

            otpToken.value = data.tokens[0].value

        }).catch((error) => {

            const data = error.data

            console.log(error.data)

            fetchError.trigger(error.data)

            toast.add({ severity: 'error', summary: 'Sign in', detail: data.error.exception, life: 3000 })

        })

        return {
            onResult: fetchResult.on,
            onError: fetchError.on
        }

    }

    const verifyOtp = (values: any) => {

        const fetchResult = createEventHook<any>()
        const fetchError = createEventHook<any>()

        $fetch(`${config.public.apiBase}/api/v1/authentication/verify_otp`, {
            method: 'POST',
            body: values,
            headers: {
                'Authorization': `Bearer ${otpToken.value}`
            }
        }).then(data => {

            console.log(data)

            fetchResult.trigger(data)

            persistToken(data.tokens[0].value)

        }).catch((error) => {

            const data = error.data

            console.log(error.data)

            fetchError.trigger(error.data)

            toast.add({ severity: 'error', summary: 'Sign in', detail: data.error.exception, life: 3000 })

        })

        return {
            onResult: fetchResult.on,
            onError: fetchError.on
        }

    }

    const persistToken = (accessToken: any) => {

        const token = useCookie('token')
        token.value = accessToken

        authenticated.value = true

        if (Capacitor.isNativePlatform()) {

            Preferences.get({ key: 'passkey' }).then(async (value: any) => {

                if (!user.value) {
                    await getCurrentUser()
                }

                if (value.value) {
                    navigateTo('/')
                } else {
                    navigateTo('/auth/passkey')
                }

            }).catch((error) => {

                navigateTo('/')
            })
        } else {
            navigateTo('/')
        }
    }

    const getToken = () => {
        const token = useCookie('token')
        return token.value
    }

    return { user, authenticated, isLoggedOut, login, register, forgotPassword, changePassword, forgotPasswordEmailSent, logout, getCurrentUser, itsMe, verifyEmail, requestOtp, verifyOtp, persistToken, getToken }

})