
// @ts-nocheck
import locale__opt_buildhome_repo_lang_en_ts from "../lang/en.ts?hash=c6112a2a&locale=en";
import locale__opt_buildhome_repo_lang_es_ts from "../lang/es.ts?hash=54ae71a0&locale=es";
import locale__opt_buildhome_repo_lang_pt_ts from "../lang/pt.ts?hash=9900afb9&locale=pt";


export const localeCodes =  [
  "en",
  "es",
  "pt"
]

export const localeLoaders = {
  "en": [{ key: "../lang/en.ts", load: () => Promise.resolve(locale__opt_buildhome_repo_lang_en_ts), cache: false }],
  "es": [{ key: "../lang/es.ts", load: () => Promise.resolve(locale__opt_buildhome_repo_lang_es_ts), cache: false }],
  "pt": [{ key: "../lang/pt.ts", load: () => Promise.resolve(locale__opt_buildhome_repo_lang_pt_ts), cache: false }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "files": [
        "/opt/buildhome/repo/lang/en.ts"
      ]
    },
    {
      "code": "es",
      "files": [
        "/opt/buildhome/repo/lang/es.ts"
      ]
    },
    {
      "code": "pt",
      "files": [
        "/opt/buildhome/repo/lang/pt.ts"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "lang",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "files": [
      {
        "path": "/opt/buildhome/repo/lang/en.ts"
      }
    ]
  },
  {
    "code": "es",
    "files": [
      {
        "path": "/opt/buildhome/repo/lang/es.ts"
      }
    ]
  },
  {
    "code": "pt",
    "files": [
      {
        "path": "/opt/buildhome/repo/lang/pt.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
