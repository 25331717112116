export default defineNuxtRouteMiddleware((to) => {

    const { authenticated } = storeToRefs(useAuthStore())
    const token = useCookie('token')

    if (token.value) {
        authenticated.value = true;
    }

    if (token.value && to?.path === '/auth/login') {
        return navigateTo('/')
    }

    if (!token.value && (!to?.path.startsWith('/auth') && !to?.path.startsWith('/legal/'))) {
        abortNavigation()
        return navigateTo('/auth/login')
    }
});