
import { App } from '@capacitor/app';

export default defineNuxtPlugin((nuxtApp) => {

    nuxtApp.hook('app:beforeMount', () => {
        App.addListener('appUrlOpen', (event) => {
            const slug = event.url.split('.com').pop()
            if(slug) {
                navigateTo(slug)
            }
        })
    })

})